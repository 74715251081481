<template>
  <section class="container pt-5 pb-3 pt-lg-8">
    <ul class="row justify-content-evenly">
      <li class="col-lg-4 col-12 mb-3">
        <div class="d-flex d-lg-block align-items-center">
          <div class="w-25 w-md-20 w-lg-50 m-auto px-3 px-lg-5 mb-3">
            <img src="@/assets/image/contact/location1.png" alt="服務說明" />
          </div>
          <div class="w-75 w-md-80 w-lg-100 px-3 px-lg-5">
            <p class="text-lg-center text-gray-500">分佈全台的13個服務據點</p>
            <p class="text-lg-center text-gray-500">
              皆建置完整的備品、零件倉及物流車隊，透過統一的管理平台進行服務調度
            </p>
          </div>
        </div>
      </li>
      <li class="col-lg-4 col-12 mb-3">
        <div class="d-flex d-lg-block align-items-center">
          <div class="w-25 w-md-20 w-lg-50 m-auto px-3 px-lg-5 mb-3">
            <img src="@/assets/image/contact/location2.png" alt="服務說明" />
          </div>
          <div class="w-75 w-md-80 w-lg-100 px-3 px-lg-5">
            <p class="text-lg-center text-gray-500">全台縣市級以上服務區域</p>
            <p class="text-lg-center text-gray-500">可提供2hrs on-site或4hrs 完修及備品服務</p>
          </div>
        </div>
      </li>
      <li class="col-lg-4 col-12">
        <div class="d-flex d-lg-block align-items-center">
          <div class="w-25 w-md-20 w-lg-50 m-auto px-3 px-lg-5 mb-3">
            <img src="@/assets/image/contact/location3.png" alt="服務說明" />
          </div>
          <div class="w-75 w-md-80 w-lg-100 px-3 px-lg-5">
            <p class="text-lg-center text-gray-500">免付費服務專線：0800-077-758提供7×24服務</p>
          </div>
        </div>
      </li>
    </ul>
  </section>

  <div class="container">
    <div class="row pt-lg-5 pb-3 align-items-center">
      <div class="col-6 col-lg-5">
        <img src="@/assets/image/contact/hpi_map_20240315.png" alt="HPI仁大資訊服務據點地圖" />
      </div>
      <div class="col-6 col-lg-7">
        <div class="pt-5 pt-lg-8 pe-lg-7">
          <ul class="row g-0">
            <li
              class="col-6 mb-3"
              :class="idx === 4 || idx === 5 ? 'mb-lg-3' : 'mb-lg-3'"
              v-for="(loc, idx) in locations"
              :key="loc.name"
            >
              <div class="row g-1">
                <div class="col-3 col-lg-2">
                  <img
                    :src="require(`@/assets/image/contact/Pin-icon${idx + 1}.png`)"
                    alt="Pin-icon"
                    class="obj-fit-contain pb-2 pin-icon"
                  />
                </div>
                <div class="col-9 col-lg-10 text-gray-500 border-5">
                  <h6 class="fs-8 fs-md-6 fs-lg-3 fw-lighter mb-1">{{ loc.name }}</h6>
                  <p class="fs-8 fw-light d-none d-lg-block">服務範圍：{{ loc.area }}</p>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContactLocation",
  data() {
    return {
      locations: [
        { name: "總公司", area: "台北市、新北市、基隆" },
        { name: "內湖辦事處", area: "台北市" },
        { name: "桃園辦事處", area: "桃園、中壢" },
        { name: "新竹辦事處", area: "新竹、苗栗" },
        { name: "台中辦事處", area: "大台中地區" },
        { name: "台南辦事處", area: "台南" },
        { name: "高雄辦事處", area: "高雄、屏東" },
        { name: "彰化辦事處", area: "彰化" },
        { name: "雲林辦事處", area: "雲林" },
        { name: "嘉義辦事處", area: "嘉義" },
        { name: "南投辦事處", area: "南投" },
        { name: "宜蘭辦事處", area: "宜蘭、羅東" },
        { name: "花蓮辦事處", area: "花蓮" },
        { name: "南科辦事處", area: "南科" },
      ],
    };
  },
};
</script>
